import { Button, FileButton, Loader } from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";
import { useState } from "react";
import useFileStore from "../model/model";

interface AddFileBtnProps {
  Id?: string;
  updateExecutionData: any;
  setUpdateExecutionData: any;
  isBtn: boolean;
  Icon?: React.ComponentType;
}

export const AddFileBtn: React.FC<AddFileBtnProps> = ({
  Id,
  updateExecutionData,
  setUpdateExecutionData,
  isBtn = true,
  Icon,
}) => {
  const { resetFileResponse, setMultipleFileResponse } = useFileStore();
  const [isFileLoading, setFileLoading] = useState(false);

  const loadingFile = async (files: any[]) => {
    try {
      setFileLoading(true);
      const currentExecutionId = Id || "";
      const existingAttachmentNames = updateExecutionData.attachments.map(
        (attachment: { name: any }) => attachment.name
      );

      // Фильтруем только новые файлы (те, имена которых отсутствуют в existingAttachmentNames)
      const filterFiles = files.filter(
        (file: { name: string }) => !existingAttachmentNames.includes(file.name)
      );
      const updatedFormFiles = [...filterFiles];

      //setFiles(updatedFormFiles);

      await setMultipleFileResponse(filterFiles, currentExecutionId);

      const updatedFileResponse = useFileStore.getState().fileResponse || [];
      const updatedFileResponseFiltered = updatedFileResponse.filter((file) =>
        updatedFormFiles.some((formFile) => formFile.name === file.name)
      );

      if (updatedFileResponseFiltered.length > 0) {
        setUpdateExecutionData((prevData: { attachments: never[] }) => {
          const currentAttachments = prevData.attachments || [];
          const newAttachments = [
            ...currentAttachments,
            ...updatedFileResponseFiltered.map((file) => ({
              name: file.name,
              content: file,
            })),
          ];

          return {
            ...prevData,
            attachments: newAttachments,
          };
        });
      }
      resetFileResponse();
    } catch (err) {
      console.error(err);
    } finally {
      setFileLoading(false);
    }
  };

  return (
    <FileButton multiple onChange={(files) => loadingFile(files)}>
      {(props) =>
        isBtn ? (
          <Button
            variant="outline"
            {...props}
            rightSection={<IconUpload size={14} />}
            loading={isFileLoading}
          >
            Загрузить файлы
          </Button>
        ) : (
          <>
            {isFileLoading ? (
              <Loader color="blue" size="xs" />
            ) : (
              Icon && (
                <div {...props} style={{ display: "flex", cursor: "pointer" }}>
                  <Icon />
                </div>
              )
            )}
          </>
        )
      }
    </FileButton>
  );
};
