import React from "react";
import { useCreateStore } from "../model/model";
import { ActionIcon, Button } from "@mantine/core";
import { IconPencilPlus } from "@tabler/icons-react";
interface AddBtnProps {
  closeDropdown: () => void; // Указываем, что closeDropdown — это функция без аргументов, возвращающая void
}

const AddBtn: React.FC<AddBtnProps> = ({ closeDropdown }) => {
  const { setIsModalOpen } = useCreateStore();

  const handleClick = () => {
    closeDropdown(); // Закрываем выпадающий список
    setIsModalOpen(true); // Открываем модальное окно
  };

  return (
    <ActionIcon
      variant="outline"
      size="lg"
      radius="md"
      aria-label="Settings"
      onClick={handleClick}
    >
      <IconPencilPlus stroke={2} />
    </ActionIcon>
  );
};

export default AddBtn;
