import {
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Anchor,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  useMantineTheme,
  Avatar,
  Flex,
  Skeleton,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";

import "./HeaderMegaMenu.css";
import { LogoSvg } from "./LogoSvg";
import Cookies from "js-cookie";
import useAuthStore from "../../store/useAuthStore";
import { Navigate, useNavigate } from "react-router-dom";
import useUsersStore from "store/UserStore";
import { useEffect } from "react";

export function HeaderMegaMenu() {
  const isMobile = useMediaQuery("(max-width: 992px)");

  const navigate = useNavigate();
  const { meUser } = useUsersStore();

  const handleDeleteCookie = () => {
    useAuthStore.getState().logout();
    navigate("/auth");
  };
  const avatarLink = meUser?.avatar?.id
    ? `${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${meUser?.avatar?.id}/download`
    : null;

  return (
    <Box className="pl-1 md:pl-3 lg:pl-5 pr-1 md:pr-3 lg:pr-5">
      <header className={"header"}>
        <Group justify="space-between" h="100%">
          {meUser !== null ? (
            <Flex gap="sm" wrap="nowrap" align="center">
              <Avatar
                src={avatarLink}
                alt={meUser?.name}
                color="blue"
                size={isMobile ? "md" : "lg"}
              >
                {meUser?.name ? meUser.name.charAt(0).toUpperCase() : ""}
              </Avatar>

              <div>
                <Text fz="sm" fw={500}>
                  {meUser?.name}
                </Text>
                <Text fz="xs" c="dimmed">
                  {meUser?.username}
                </Text>
              </div>
            </Flex>
          ) : (
            <Flex gap="sm" wrap="nowrap">
              <Skeleton height={50} circle mb="xl" />

              <div>
                <Skeleton height={8} mt={6} width="70%" radius="xl" />
                <Skeleton height={8} mt={6} width="70%" radius="xl" />
              </div>
            </Flex>
          )}
          <Group>
            <Button
              variant="default"
              onClick={handleDeleteCookie}
              radius="md"
              size="md"
            >
              Выйти
            </Button>
          </Group>
        </Group>
      </header>
    </Box>
  );
}
