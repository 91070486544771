import React from "react";
import { Anchor, CloseButton } from "@mantine/core";
import style from "./DocumentCard.module.scss";
import pdfIcon from "../../../../img/Type=PDF.svg";
import docIcon from "../../../../img/Type=DOC.svg";
import fileIcon from "../../../../img/Type=FILE.svg";
import filePng from "../../../../img/Type=PNG.svg";
import fileJpg from "../../../../img/TypeJPG.svg";
// @ts-ignore
import Arrow from "../../../../img/arr.svg?react";
import clsx from "clsx";

interface Props {
  hasDelete: boolean;
  documentData: any;
  onDelete?: (id: string) => void; // Function to delete the document
}

const DocumentCard: React.FC<Props> = ({
  hasDelete,
  documentData,
  onDelete,
}) => {
  function bytesToMB(bytes: number): number {
    return bytes / (1024 * 1024);
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(documentData?.content?.id || documentData?.id);
    }
  };

  const getIconSrc = (type?: string): string => {
    switch (type) {
      case "application/pdf":
        return pdfIcon;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return docIcon;
      case "image/jpeg":
        return fileJpg;
      case "image/png":
        return filePng;
      default:
        return fileIcon;
    }
  };

  if (!documentData) {
    return <div>Invalid document data</div>;
  }

  const id = documentData.content?.id || documentData.id;
  const type = documentData.content?.type || documentData.type;
  const size = documentData.content?.size || documentData.size;
  const name = documentData.content?.name || documentData.name;

  return (
    <div className={style.cardDocumentWrapper}>
      <div className={style.cardDocumentWrapperWithClose}>
        <div
          className={clsx(style.cardDocument, {
            [style.cardDocumentInMessage]: !hasDelete,
          })}
        >
          <img
            src={getIconSrc(type)}
            alt={type}
            className={style.documentIcon}
          />
          <div className={style.documentInfo}>
            <Anchor
              href={`${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${id}/view`}
              target="_blank"
              className={style.documentName}
            >
              {name}
            </Anchor>
            <p className={style.documentSize}>{`${bytesToMB(size).toFixed(
              2
            )} Mb`}</p>
          </div>
        </div>
        {hasDelete && (
          <CloseButton size="sm" color="red" onClick={handleDelete} />
        )}
      </div>
      {!hasDelete && (
        <div className={style.openArea}>
          <Anchor
            href={`${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${id}/view`}
            target="_blank"
            className={style.openLabel}
          >
            Открыть
          </Anchor>

          <Arrow className={style.openArrow} />
        </div>
      )}
    </div>
  );
};

export default DocumentCard;
