import create from "zustand";
import AuthService from "../services/AuthService";
import Cookies from "js-cookie";

interface AuthStore {
  username: string;
  password: string;
  accessToken: string | null;
  refreshToken: string | null;
  error: string | null;
  setError: (error: string | null) => void;
  setUsername: (username: string) => void;
  setPassword: (password: string) => void;
  login: () => Promise<void>;
  refresh: () => Promise<void>;
  logout: () => void; // New logout action
  loading: boolean;
}

const useAuthStore = create<AuthStore>((set, get) => ({
  username: "",
  password: "",
  accessToken: null,
  refreshToken: Cookies.get("refreshToken") || null,
  loading: false,
  error: null,

  setError: (error: string | null) => {
    set({ error });
  },

  setUsername: (username: string) => {
    set({ username });
  },

  setPassword: (password: string) => {
    set({ password });
  },

  login: async () => {
    set({ loading: true });

    const response = await AuthService.authorization({
      username: get().username,
      password: get().password,
      grant_type: "password",
      scope: "web",
    });

    set({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });
    Cookies.set("refreshToken", response.data.refresh_token, { expires: 7 });
    localStorage.setItem("accessToken", response.data.access_token);
    set({ loading: false });
  },

  refresh: async () => {
    set({ loading: true });

    const response = await AuthService.authorizationRefresh({
      refresh_token: Cookies.get("refreshToken")!,
      grant_type: "refresh_token",
      scope: "web",
    });

    set({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });
    Cookies.set("refreshToken", response.data.refresh_token, { expires: 7 });

    set({ loading: false });
  },

  logout: () => {
    Cookies.remove("refreshToken");
    localStorage.removeItem("accessToken");
  },
}));

export default useAuthStore;
