import { Client, StompSubscription } from "@stomp/stompjs";
import { useEffect, useRef, useState } from "react";
import { Message } from "../api/model";
import { useChatStore } from "../model/model";
import useUsersStore from "store/UserStore";

const useWebSocketChat = (chatId: string) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const clientRef = useRef<Client | null>(null);
  const { meUser } = useUsersStore();

  const { postMessage } = useChatStore();
  useEffect(() => {
    const backAddress = process.env.REACT_APP_BACK_ADDRESS;
    if (!backAddress) {
      throw new Error("REACT_APP_BACK_ADDRESS is not defined");
    }

    const socketHost = new URL(backAddress).host;
    const stompClient = new Client({
      brokerURL: `wss://${socketHost}/api/ws?access_token=${localStorage.getItem(
        "accessToken"
      )}`,
      connectHeaders: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      debug: function (str: any) {
        console.log(str);
      },
      reconnectDelay: 5000,
    });
    console.log("stompClient", stompClient);
    stompClient.onConnect = () => {
      console.log("Connected to WebSocket");

      const chatSubscription: StompSubscription = stompClient.subscribe(
        `/topic/send-message/${chatId}`,
        (message: { body: string }) => {
          console.log(`Received raw message: ${message.body}`);
          let parsedMessage: Message | null = null;
          try {
            parsedMessage = JSON.parse(message.body) as Message;
          } catch (e) {
            console.error(e);
          }
          if (parsedMessage) {
            console.log(`Parsed message: ${JSON.stringify(parsedMessage)}`);
            parsedMessage.iamSender = parsedMessage.sender.id === meUser?.id;
            setMessages((prevMessages) => [
              ...prevMessages,
              parsedMessage as Message,
            ]);
            console.log(
              "parsedMessage.iamSender",
              parsedMessage.sender.id,
              meUser
            );
          }
        }
      );

      clientRef.current = stompClient;

      return () => {
        chatSubscription.unsubscribe();
      };
    };

    stompClient.activate();

    return () => {
      stompClient.deactivate();
    };
  }, [chatId, meUser]);

  return { messages, postMessage };
};

export default useWebSocketChat;
