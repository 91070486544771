import create from "zustand";
import { Chat, ChatMessagesResponse } from "../api/model";
import ChatService from "../api";

interface ChatState {
  chatById: Chat | null;
  getChatsById: (id: string) => void;
  chatsMessage: ChatMessagesResponse | null;
  getChatsMessage: (
    id: string,
    params?: { size: number; page: number }
  ) => Promise<ChatMessagesResponse>;
  error: string | null;
  postMessage: (
    id: string,
    content: string,
    attachments?: any
  ) => Promise<void>;
}

export const useChatStore = create<ChatState>((set) => ({
  chatsMessage: null,
  chatById: null,
  error: null,

  getChatsById: async (id: string) => {
    try {
      const response = await ChatService.getChatsById(id);
      set({ chatById: response.data });
    } catch (err) {
      console.error(err);
    }
  },

  getChatsMessage: async (
    id: string,
    params?: { size: number; page: number }
  ): Promise<ChatMessagesResponse> => {
    try {
      const response = await ChatService.getChatsMessages(id, params);
      set({ chatsMessage: response.data });
      return response.data;
    } catch (err) {
      console.error(err);
      // Return a default ChatMessagesResponse object in case of error
      return { items: [], totalCount: 0 };
    }
  },

  postMessage: async (id: string, content: string, attachments?: any) => {
    set({ error: null });
    try {
      await ChatService.postMessage(id, {
        content: content,
        attachments: attachments,
      });
    } catch (err) {
      console.error(err);
      set({ error: "Ошибка при отправке сообщений" });
    }
  },
}));
