import { AxiosResponse } from "axios";
import $api from "../http";
import { IFile } from "../models/File/File";

export default class FileService {
  static async fileUpload(file: File): Promise<AxiosResponse<IFile>> {
    const formData = new FormData();
    formData.append("data", file);

    try {
      const response = await $api.post("/file-storage/upload", formData);

      return response.data as AxiosResponse<IFile>;
    } catch (error) {
      throw error;
    }
  }
}
