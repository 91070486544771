import React, { useCallback } from "react";
import {
  Table,
  ActionIcon,
  Group,
  Skeleton,
  TypographyStylesProvider,
} from "@mantine/core";
import { IconPencil } from "@tabler/icons-react";
import { Execution } from "../../../models/List/OrdersExecution";
import ExpandedRow from "./ExpandedRow";
import useUsersStore from "store/UserStore";
import useOrderStore from "store/OrderStore";

interface TableRowProps {
  item: Execution;
  toggleRow: (id: string) => void;
  opened: boolean;
  downloadLoadingMap: Record<string, boolean>;
  downloadPhotos: (item: Execution) => void;
  isLoading: boolean;
}

const TableRowComponent = ({
  item,
  toggleRow,
  opened,
  downloadLoadingMap,
  downloadPhotos,
  isLoading,
}: TableRowProps) => {
  const { meUser } = useUsersStore();
  const { setIsExecutionModalOpen, setFormFiles } = useOrderStore();

  return (
    <>
      <Table.Tr>
        <Table.Td>
          <Skeleton visible={isLoading}>
            {new Intl.DateTimeFormat("ru-RU", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(item.createDate))}{" "}
          </Skeleton>
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isLoading}>
            {item.attachments?.length || item.content ? (
              <div
                onClick={() => toggleRow(item.id)}
                className="flex items-center gap-2"
              >
                <TypographyStylesProvider>
                  <div dangerouslySetInnerHTML={{ __html: item.name }} />
                </TypographyStylesProvider>

                <div style={{ transform: opened ? "rotate(180deg)" : "" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#25282B"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: item.name }} />
              </TypographyStylesProvider>
            )}
          </Skeleton>
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isLoading}>
            <Group justify="space-between">
              {item.user.name}
              {(meUser?.authorities.includes("UPDATE_EXECUTION_AUTHORITY") ||
                meUser?.id === item.user.id) && (
                <ActionIcon variant="subtle" color="gray">
                  <IconPencil
                    style={{ width: "16px", height: "16px" }}
                    stroke={1.5}
                    onClick={() => {
                      setIsExecutionModalOpen(true, item.id);
                      setFormFiles({});
                    }}
                  />
                </ActionIcon>
              )}
            </Group>
          </Skeleton>
        </Table.Td>
      </Table.Tr>

      {opened && !isLoading && (
        <ExpandedRow
          item={item}
          downloadPhotos={downloadPhotos}
          downloadLoadingMap={downloadLoadingMap}
        />
      )}
    </>
  );
};

const TableRow = React.memo(TableRowComponent);
export default TableRow;
