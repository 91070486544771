import { AxiosResponse } from "axios";
import { Order } from "models/List/TrackingOrders";
import $apiWithHeaders from "components/Chat/api/apiWithHeaders";

export default class AddOrderService {
  static async addOrder(number: string): Promise<AxiosResponse<Order>> {
    try {
      const response = await $apiWithHeaders.post<Order>(
        "tracking/orders/create",
        {
          number,
        }
      );
      return response;
    } catch (error) {
      console.error("Ошибка при добавлении заказа:", error);
      throw error;
    }
  }
}
