import $api from "../http";
import { AxiosResponse } from "axios";
import { CompleteTask } from "../models/Task/TaskComplete";

export default class TaskService {
  static async taskComplete(
    orderId: string,
    taskId: string,
    completeTask: CompleteTask
  ): Promise<AxiosResponse<any>> {
    const endpoint = `/tracking/orders/${orderId}/tasks/${taskId}/complete`;

    try {
      const response = await $api.post(endpoint, completeTask, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
      });

      return response.data as AxiosResponse<any>;
    } catch (error) {
      throw error;
    }
  }
}
