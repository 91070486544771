import { AxiosResponse } from "axios";
import $api from "../http";

interface SubResponse {
  value: string;
}
export default class SubService {
  static async telegramSubscribe(): Promise<AxiosResponse<SubResponse>> {
    const basicAuthHeader = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    return $api.post<SubResponse>("/tracking/notifications/subscribe", null, {
      headers: { ...basicAuthHeader },
    });
  }
}
