import $api from "../http";
import { AxiosResponse } from "axios";
import { Order, TrackingOrders } from "../models/List/TrackingOrders";
import { Execution, OrdersExecution } from "../models/List/OrdersExecution";
import { OneTask, Tasks } from "../models/Task/Task";
import { IUpdateExecution } from "models/List/UpdateExecotion";
export default class OrderService {
  static interceptors: any;
  static async getOrders(
    page: number,
    size: number,
    searchString?: string | undefined,
    customerLogin?: string | undefined
  ): Promise<AxiosResponse<TrackingOrders>> {
    const basicAuthHeader = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };

    return $api.get<TrackingOrders>("/tracking/orders", {
      headers: { ...basicAuthHeader },
      params: { page, size, searchString, customerLogin },
    });
  }
  static async getOrderExecutions(
    id: string
  ): Promise<AxiosResponse<OrdersExecution>> {
    const basicAuthHeader = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    return $api.get<OrdersExecution>(`/tracking/orders/${id}/executions`, {
      headers: { ...basicAuthHeader },
    });
  }

  static async getOrderById(id: string): Promise<AxiosResponse<Order>> {
    const basicAuthHeader = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    return $api.get<Order>(`/tracking/orders/${id}`, {
      headers: { ...basicAuthHeader },
    });
  }
  static async getOrdersTasks(id: string): Promise<AxiosResponse<Tasks>> {
    const basicAuthHeader = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    return $api.get<Tasks>(`/tracking/orders/${id}/tasks`, {
      headers: { ...basicAuthHeader },
    });
  }
  static async getTaskById(
    OrderId: string,
    TaskId: string
  ): Promise<AxiosResponse<OneTask>> {
    const basicAuthHeader = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    return $api.get<OneTask>(`/tracking/orders/${OrderId}/tasks/${TaskId}`, {
      headers: { ...basicAuthHeader },
    });
  }
  static async postExecutionUpdate(
    OrderId: string,
    ExecutionId: string,
    executionUpdateData: IUpdateExecution
  ): Promise<AxiosResponse<Execution>> {
    const basicAuthHeader = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    return $api.post<Execution>(
      `/tracking/orders/${OrderId}/executions/${ExecutionId}/update`,
      executionUpdateData,
      {
        headers: { ...basicAuthHeader },
      }
    );
  }
}
