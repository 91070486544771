import create from "zustand";

import { Chat, Message } from "../api/model";
import ChatService from "../api";

interface ChatState {
  allChats: Chat[];
  getChats: () => void;
  chatById: Chat | null;
  getChatsById: (id: string) => void;
  chatsMessage: Message[];
  getChatsMessage: (id: string) => void;
  error: string | null;
  postMessage: (id: string, content: string, attachemnts?: any) => void;
}

export const useChatStore = create<ChatState>((set) => ({
  allChats: [],
  chatsMessage: [],

  chatById: null,
  error: null,

  getChats: async () => {
    try {
      const response = await ChatService.getChats();
      set({
        allChats: response.data,
      });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
  getChatsById: async (id: string) => {
    try {
      const response = await ChatService.getChatsById(id);
      set({ chatById: response.data });
    } catch (err) {
      console.error(err);
    }
  },
  getChatsMessage: async (id: string) => {
    try {
      const response = await ChatService.getChatsMessages(id);
      set({ chatsMessage: response.data.items });
    } catch (err) {
      console.error(err);
    }
  },
  postMessage: async (id: string, content: string, attachemnts?: any) => {
    set({ error: null });

    try {
      await ChatService.postMessage(id, {
        content: content,
        attachments: attachemnts,
      });
    } catch (err) {
      console.error(err);
      set({ error: "Ошибка при отправке сообщений" });
    }
  },
}));
