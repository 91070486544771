import { AxiosResponse } from "axios";
import queryString from "query-string";

import $apiWithHeaders from "./apiWithHeaders";
import {
  Chat,
  ChatMessagesResponse,
  SearchParams,
  SendMessageParams,
} from "./model";

export default class ChatService {
  static async getChats(
    searchParams?: SearchParams
  ): Promise<AxiosResponse<Chat[]>> {
    const pageableParams =
      searchParams && queryString.stringify({ ...searchParams });
    try {
      const response = await $apiWithHeaders.get<Chat[]>(
        `api/chats?${pageableParams}`
      );
      return response;
    } catch (error) {
      console.error("Ошибка при получении списка чатов:", error);
      throw error;
    }
  }

  static async getChatsById(
    id: string,
    history = false
  ): Promise<AxiosResponse<Chat>> {
    try {
      const response = await $apiWithHeaders.get<Chat>(`api/chats/${id}`, {
        params: { history },
      });
      return response;
    } catch (error) {
      console.error("Ошибка при получении чата:", error);
      throw error;
    }
  }

  static async getChatsMessages(
    id: string,
    searchParams?: SearchParams
  ): Promise<AxiosResponse<ChatMessagesResponse>> {
    try {
      const pageableParams =
        searchParams && queryString.stringify({ ...searchParams });
      const response = await $apiWithHeaders.get<ChatMessagesResponse>(
        `api/chats/${id}/messages${pageableParams}`
      );
      return response;
    } catch (error) {
      console.error("Ошибка при получении сообщений чата:", error);
      throw error;
    }
  }
  static async getChatsByIdUnreadCount(
    id: string
  ): Promise<AxiosResponse<number>> {
    try {
      const response = await $apiWithHeaders.get<number>(
        `api/chats/${id}/messages/unread/count`
      );
      return response;
    } catch (error) {
      console.error(
        "Ошибка при получении количества непрочитанных сообщений чата:",
        error
      );
      throw error;
    }
  }
  static async getAllChatsUnreadCount(): Promise<AxiosResponse<number>> {
    try {
      const response = await $apiWithHeaders.post<number>(
        `api/chats/messages/unread/count`
      );
      return response;
    } catch (error) {
      console.error(
        "Ошибка при получении количества всех непрочитанных сообщений:",
        error
      );
      throw error;
    }
  }
  static async postMessage(
    id: string,
    params: SendMessageParams
  ): Promise<AxiosResponse> {
    try {
      const response = await $apiWithHeaders.post(
        `api/chats/${id}/send`,
        params
      );
      return response;
    } catch (error) {
      console.error("Ошибка при создании сообщения:", error);
      throw error;
    }
  }
}
