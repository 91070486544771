import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Table, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import Th from "./Th";
import TableRow from "./TableRow";
import {
  Execution,
  OrdersExecution,
} from "../../../models/List/OrdersExecution";

import "./TableSort.css";

const filterData = (rows: Execution[], search: string) => {
  const query = search.toLowerCase().trim();
  return rows.filter((item) =>
    Object.keys(item).some((key) =>
      String(item[key as keyof Execution])
        .toLowerCase()
        .includes(query)
    )
  );
};

const zipWorker = new Worker(new URL("./zipWorker.js", import.meta.url));

interface TableSortProps {
  isLoading: boolean;
  orderExecutions: any;
}

export function TableSort({
  isLoading,
  orderExecutions,
}: TableSortProps): JSX.Element {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<keyof Execution | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [openedRows, setOpenedRows] = useState<Record<string, boolean>>({});
  const [downloadLoadingMap, setDownloadLoadingMap] = useState<
    Record<string, boolean>
  >({});

  const rows: Execution[] = (orderExecutions as OrdersExecution)?.items ?? [];

  useEffect(() => {
    if (rows.length > 0) {
      setOpenedRows((prev) => {
        const updated = { ...prev };
        rows.forEach((item) => {
          if (item.attachments?.length || item.content) {
            updated[item.id] = true;
          }
        });
        return updated;
      });
    }
  }, [rows]);

  const toggleRow = useCallback((rowId: string) => {
    setOpenedRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  }, []);

  const sortedData = useMemo(() => {
    const filteredData = filterData(rows, search);
    if (!sortBy) return filteredData;

    return filteredData.sort((a, b) => {
      const aValue = a[sortBy] || "";
      const bValue = b[sortBy] || "";
      return reverseSortDirection
        ? bValue.localeCompare(aValue)
        : aValue.localeCompare(bValue);
    });
  }, [rows, search, sortBy, reverseSortDirection]);

  const setSorting = useCallback(
    (field: keyof Execution) => {
      setReverseSortDirection((prev) => (field === sortBy ? !prev : false));
      setSortBy(field);
    },
    [sortBy]
  );

  const downloadPhotos = useCallback(
    (item: Execution) => {
      if (downloadLoadingMap[item.id]) return;

      setDownloadLoadingMap((prev) => ({ ...prev, [item.id]: true }));

      zipWorker.postMessage({
        attachments: item.attachments,
        baseUrl: process.env.REACT_APP_BACK_ADDRESS,
      });

      zipWorker.onmessage = function (e) {
        const zipBlob = e.data;
        saveAs(zipBlob, `Фото ${item.name || "Photos"}.zip`);
        setDownloadLoadingMap((prev) => ({ ...prev, [item.id]: false }));
      };
    },
    [downloadLoadingMap]
  );

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  return (
    <>
      <TextInput
        placeholder="Введите запрос для поиска в текущем трекинге"
        mb="md"
        radius="md"
        leftSection={
          <IconSearch style={{ width: "16px", height: "16px" }} stroke={1.5} />
        }
        value={search}
        onChange={(event) => handleSearchChange(event.currentTarget.value)}
      />
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        miw="100%"
        layout="fixed"
      >
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "40%" }} />
          <col style={{ width: "30%" }} />
        </colgroup>
        <Table.Tbody>
          <Table.Tr>
            <Th
              sorted={sortBy === "createDate"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("createDate")}
            >
              Дата
            </Th>
            <Th
              sorted={sortBy === "name"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("name")}
            >
              Событие
            </Th>
            <Th
              sorted={sortBy === "user.name"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("user.name")}
            >
              Автор
            </Th>
          </Table.Tr>
          {sortedData.map((item: Execution) => (
            <TableRow
              key={item.id}
              item={item}
              toggleRow={toggleRow}
              opened={openedRows[item.id]}
              downloadPhotos={downloadPhotos}
              downloadLoadingMap={downloadLoadingMap}
              isLoading={isLoading}
            />
          ))}
        </Table.Tbody>
      </Table>
    </>
  );
}
