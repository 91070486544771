import $api from "../http";
import { AxiosResponse } from "axios";
import { AuthorizationResponse } from "../models/Authorization/AuthorizationResponse";
import { IAuth } from "../models/Authorization/Auth";
import { IRefresh } from "../models/Authorization/Refresh";

const clientId = "client";
const clientSecret = "b3251795-5d6a-47ae-8667-0a268246e33a";
const scope = "web";

export default class AuthService {
  static async authorization(
    Auth: IAuth
  ): Promise<AxiosResponse<AuthorizationResponse>> {
    const formData = new FormData();
    formData.append("username", Auth.username);
    formData.append("password", Auth.password);
    formData.append("grant_type", Auth.grant_type);
    formData.append("scope", Auth.scope);

    const basicAuthHeader = {
      Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
    };

    return $api.post("/oauth/token", formData, {
      headers: {
        ...basicAuthHeader,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static async authorizationRefresh(
    Refresh: IRefresh
  ): Promise<AxiosResponse<AuthorizationResponse>> {
    const formData = new FormData();

    formData.append("grant_type", Refresh.grant_type);
    formData.append("scope", Refresh.scope);
    formData.append("refresh_token", Refresh.refresh_token);
    const basicAuthHeader = {
      Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
    };

    return $api.post("/oauth/token", formData, {
      headers: {
        ...basicAuthHeader,
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
